import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Images = () => {

    const { contentfulImages } = useStaticQuery(graphql`
        query {
            contentfulImages {
                pageName
                images {
                    description
                    fluid {
                        ...GatsbyContentfulFluid
                    }
                }
                pageName
            }
        }
    `);

    return (
        <Layout>
            <SEO title="Imagenes" />
            <div id="imagesContentElem" >
                <h1>{ contentfulImages.pageName }</h1>
                <div style={{display: 'flex', justifyContent:'space-around', flexFlow: 3, flexWrap: 'wrap', alignContent: 'stretch'}}>
                    {
                        (contentfulImages.images !== undefined) && 
                        contentfulImages.images.filter((elem) => elem.description !== 'default').map((elem) =>
                            <div style={{width: '300px'}}>
                                <Img fluid={contentfulImages.images[0].fluid} imgStyle={{position:'absolute', width:'300px', height:'300px'}}/>
                            </div>
                        )
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Images;